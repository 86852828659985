import { Controller } from "@hotwired/stimulus";
import { amountToCurrency, buildSpinner, getMetaContent } from "./helpers";
import { APPEARANCE_OPTIONS } from "./stripe";

// Connects to data-controller="stripe-subscribe"
export default class extends Controller {
  static values = {
    amount: Number, // Amount in cents
    createPath: String, // Path to create subscription
    priceId: String, // Stripe price ID
    returnUrl: String, // Location to redirect to after payment is complete
    verifyCouponPath: String, // Path to verify coupon
  };

  static targets = [
    "couponButton",
    "couponInput",
    "couponSuccess",
    "error",
    "form",
    "payment",
    "removeCouponButton",
    "submit",
  ];

  connect() {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", () => this.initializeStripe());

      return;
    }

    this.initializeStripe();
    this.validCoupon = false;
  }

  initializeStripe() {
    const publicKey = getMetaContent("stripe-public-key");

    if (window.Stripe) {
      this.stripe = Stripe(publicKey);
      this.setupStripeForm();

      return;
    }

    setTimeout(() => this.initializeStripe(), 50);
  }

  setupStripeForm() {
    this.formTarget.addEventListener("submit", this.onSubmit.bind(this));
    this.submitContentElement = this.submitTarget.firstElementChild;
    this.initialSubmitText = this.submitContentElement.innerText;
    this.initialCouponButtonText = this.couponButtonTarget.innerText;
    this.buildStripeElements();
  }

  setLoadingState() {
    const spinner = buildSpinner();

    this.clearError();
    this.submitTarget.disabled = true;
    this.submitContentElement.innerText = "";
    this.submitContentElement.appendChild(spinner);
  }

  clearLoadingState() {
    this.submitTarget.disabled = false;
    this.submitContentElement.innerText = this.initialSubmitText;
  }

  renderError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.classList.remove("hidden");
  }

  clearError() {
    this.errorTarget.textContent = "";
    this.errorTarget.classList.add("hidden");
  }

  generateOptions() {
    return {
      mode: "subscription",
      amount: this.amountValue,
      currency: "usd",
      ...APPEARANCE_OPTIONS,
    };
  }

  buildStripeElements() {
    this.elements = this.stripe.elements(this.generateOptions());
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount(this.paymentTarget);
  }

  async createSubscription() {
    const price_id = this.priceIdValue;
    const coupon_id = this.validCoupon ? this.couponInputTarget.value : null;

    return fetch(this.createPathValue, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
      body: JSON.stringify({
        subscription: {
          price_id,
          coupon_id,
        },
      }),
    }).catch((error) => {
      this.renderError(error.message);
      this.clearLoadingState();
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setLoadingState();

    const { error: submitError } = await this.elements.submit();

    if (submitError) {
      this.clearLoadingState();
      return;
    }

    const res = await this.createSubscription();

    const { id, type, clientSecret } = await res.json().catch((error) => {
      this.renderError("Something went wrong. Please try again later.");
      this.clearLoadingState();
    });

    const confirmIntent = type === "setup" ? this.stripe.confirmSetup : this.stripe.confirmPayment;

    const { error } = await confirmIntent({
      elements: this.elements,
      clientSecret,
      confirmParams: {
        return_url: `${this.returnUrlValue}?id=${id}`,
      },
    });

    if (error) {
      this.renderError(error.message);
      this.clearLoadingState();
      return;
    }

    this.clearLoadingState();
  }

  onCouponChange(event) {
    const { value } = event.target;

    if (value.trim().length === 0) {
      this.couponButtonTarget.disabled = true;
      return;
    }

    this.couponButtonTarget.disabled = false;
  }

  verifyCoupon() {
    this.setCouponLoadingState();
    const coupon = this.couponInputTarget.value;

    fetch(this.verifyCouponPathValue, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
      body: JSON.stringify({ coupon }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.clearCouponLoadingState();

        if (data.error) {
          this.renderError(data.error);
          return;
        }

        this.handleCoupon(data);
      })
      .catch((error) => {
        this.clearCouponLoadingState();
        this.renderError(error.message);
      });
  }

  handleCoupon(data) {
    const { valid } = data;

    if (!valid) {
      this.renderError("Invalid coupon");
      this.validCoupon = false;
      return;
    }

    this.clearError();
    this.couponSuccessTarget.innerText = this.getCouponSuccessText(data);
    this.couponSuccessTarget.classList.remove("hidden");
    this.couponInputTarget.disabled = true;
    this.removeCouponButtonTarget.classList.remove("hidden");
    this.couponButtonTarget.classList.add("hidden");
    this.validCoupon = true;
  }

  getCouponSuccessText(data) {
    const { amount_off, percent_off, name } = data;
    const prefix = `${name} applied.`;
    const suffix = `off, heck yeah!`;

    if (amount_off) {
      return `${prefix} ${amountToCurrency(amount_off)} ${suffix}`;
    }

    return `${prefix} ${percent_off}% ${suffix}`;
  }

  setCouponLoadingState() {
    const spinner = buildSpinner();
    const minWidth = this.couponButtonTarget.offsetWidth;

    this.couponButtonTarget.disabled = true;
    this.couponButtonTarget.style.minWidth = `${minWidth}px`;
    this.couponButtonTarget.innerText = "";
    this.couponButtonTarget.appendChild(spinner);
  }

  clearCouponLoadingState() {
    this.couponButtonTarget.disabled = false;
    this.couponButtonTarget.innerText = this.initialCouponButtonText;
  }

  removeCoupon() {
    this.couponButtonTarget.classList.remove("hidden");
    this.removeCouponButtonTarget.classList.add("hidden");
    this.couponSuccessTarget.classList.add("hidden");
    this.couponInputTarget.disabled = false;
    this.couponInputTarget.value = "";
    this.validCoupon = false;
  }

  disconnect() {
    if (!this.paymentElement) {
      return;
    }

    this.paymentElement.unmount();
  }
}
