import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="expanding-textarea"
export default class extends Controller {
  initialize() {
    this.maxLines = parseInt(this.data.get("maxLines") || 4);
    this.handleWindowResize = throttle(this.handleWindowResize, 100);
    this.setStyling();
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener("resize", this.handleWindowResize.bind(this));
    this.element.addEventListener("input", this.resizeTextarea.bind(this));
  }

  handleWindowResize(e) {
    this.setStyling();
  }

  setStyling() {
    this.setMaxHeight();
    this.resizeTextarea();
  }

  computedPaddingY() {
    const computedStyle = window.getComputedStyle(this.element);
    const paddingTop = computedStyle.getPropertyValue("padding-top");
    const paddingBottom = computedStyle.getPropertyValue("padding-bottom");
    return parseInt(paddingTop) + parseInt(paddingBottom);
  }

  computedLineHeight() {
    const computedStyle = window.getComputedStyle(this.element);
    const lineHeight = computedStyle.getPropertyValue("line-height");
    return parseInt(lineHeight);
  }

  setMaxHeight() {
    const calculatedMaxHeight =
      this.maxLines * this.computedLineHeight() + this.computedPaddingY();
    this.element.style.maxHeight = `${calculatedMaxHeight}px`;
  }

  resizeTextarea() {
    this.element.style.height = "auto";
    if (this.element.scrollHeight <= this.element.offsetHeight) return;

    this.element.style.height = `${this.element.scrollHeight}px`;
  }

  disconnect() {
    window.removeEventListener("resize", this.handleWindowResize.bind(this));
    this.element.removeEventListener("input", this.resizeTextarea.bind(this));
  }
}
